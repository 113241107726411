/*===== Files that compile in main.min.js =======*/

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
require('bootstrap');

$alfJq = jQuery.noConflict();
$alfJq(function () {
  try {
    var em = window.addEventListener ? 'addEventListener' : 'attachEvent';
    window[em]((em == 'attachEvent') ? 'onmessage' : 'message', function (e) {
      try {
        var data = JSON.parse(e.data);
        switch (data.action) {
          case 'sizeHeight':
            $alfJq('iframe#assurland').css({
              height: data.value
            });
            break;
          case 'scrollTop':
            $alfJq('html, body').stop().animate({
              scrollTop: ($alfJq('iframe#assurland').offset().top + data.value)
            }, (data.value == 0) ? 0 : 1200, null);
            break;
        }
      } catch (e) { }
    }, false);
  } catch (e) { }
});

jQuery('.top-button').click(function () {
  var scroll_el = jQuery(this).attr('href');
  if (jQuery(scroll_el).length != 0) {
    jQuery('html, body').animate({scrollTop: jQuery(scroll_el).offset().top}, 1000);
  }
  return false;
});
